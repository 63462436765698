<template>
	<header>
		<img src="favicon.png" />
		<h2>Hartismail v3.0</h2>
		<a target="_blank"
			href="https://www.hartismere.com/Parents/Hartismails-and-letters/Hartismail-v30">Instructions...
		</a>
		<br />
		<button @click="View = 'preview'" :class="{ selected: View == 'preview' }">
			<i class="fa-light fa-file-invoice"></i> Preview
		</button>
		<button @click="View = 'sms'" :class="{ selected: View == 'sms' }">
			<i class="fa-light fa-comment-sms" :style="!SMS.enabled ? 'color:#aaa' : ''"></i> SMS Text
		</button>
		<button @click="View = 'students'" :class="{ selected: View == 'students' }">
			<i class="fa-light fa-list-check"></i> Students
		</button>
		<button @click="View = 'recipients'" :class="{ selected: View == 'recipients' }">
			<i class="fa-light fa-people"></i> Recipients
		</button>
		<button @click="View = 'returns'" :class="{ selected: View == 'returns' }">
			<i class="fa-light fa-turn-down-left"></i> Returns
		</button>
		<button :class="{
			selected: View == 'problems'
		}" @click="View = 'problems'">
			<i class="fa-light fa-hexagon-exclamation" :class="{
			error:
				WebItem?.Documents.filter((v) => v.FileSize / 1024 / 1024 > 20).length > 0 ||
				(SMS.enabled && NonSMSPeople.length > 0) ||
				NonHartismailPeople.length > 0 ||
				(SMS.enabled && SMS.text.length > SMS.maxLength()) ||
				!ReturnsValidity
		}"></i>
			Problems
		</button>
		<button @click="View = 'process'" :class="{ selected: View == 'process' }">
			<i class="fa-light fa-paper-plane-top"></i> Process
		</button>
	</header>
	<preview v-if="View == 'preview' && WebItem" :AdditionalText="AdditionalText" :WebItem="WebItem" :HTML="ItemHTML"
		:Text="ItemText"></preview>
	<students v-if="View == 'students' && Classes.length && People.length" :Classes="Classes" :People="People"
		@update="(e) => (ViewablePeople = e)" :viewablePeople="ViewablePeople" :sms="SMS.enabled">
	</students>
	<template v-else-if="View == 'students'">
		<div>Loading stuff...</div>
	</template>
	<recipients v-if="View == 'recipients'" :People="People" :SelectedPeople="SelectedPeople"
		:HartismailPeople="HartismailPeople" :SMSNumbers="SMSNumbers"
		:additionalEmailAddresses="AdditionalEmailAddresses" :sms="SMS.enabled"
		:smsadditionalnumbers="SMS.additionalNumbers" @update-emails="(e) => (AdditionalEmailAddresses = e)"
		@update-numbers="(e) => (SMS.additionalNumbers = e)">
	</recipients>
	<sms v-if="View == 'sms'" :SMS="SMS" :WebItem="WebItem" />
	<returns v-if="View == 'returns'" :returnsData="ReturnsData" :webItem="WebItem" v-model="ReturnsDetails" />
	<problems v-if="View == 'problems'" :People="People" :SelectedPeople="SelectedPeople"
		:NonHartismailPeople="NonHartismailPeople" :NonSMSPeople="NonSMSPeople" :SMSNumbers="SMSNumbers"
		:WebItem="WebItem" :SMS="SMS" :ReturnsValidity="ReturnsValidity" />
	<process v-if="View == 'process'" :People="People" :SelectedPeople="SelectedPeople"
		:HartismailPeople="HartismailPeople" :AdditionalEmailAddresses="AdditionalEmailAddresses"
		:HartismailAddresses="HartismailAddresses" :WebItem="WebItem" :SMSNumbers="SMSNumbers"
		:AdditionalNumbers="SMS.additionalNumbers" :SMS="SMS" :Log="Log" @send="HandleSend" @clear-log="
			IO({ data: { action: 'clearlog', id: WebItem.Id } });
		Log = [];
		" />
	<footer></footer>
</template>

<script>
import axios from "axios";

import { computed, defineAsyncComponent } from "vue";

import preview from "@/components/preview.vue";
import students from "@/components/students.vue";
import recipients from "@/components/recipients.vue";
import sms from "@/components/sms.vue";
import problems from "@/components/problems.vue";
import process from "@/components/process.vue";
import returns from "@/components/returns.vue";

export default {
	name: "App",
	components: { students, preview, recipients, sms, problems, process, returns },
	data: function () {
		return {
			People: [],
			Classes: [],
			ViewablePeople: [],
			View: "preview",
			AdditionalEmailAddresses: [],
			WebItem: null,
			SMS: {
				enabled: false,
				text: "",
				additionalNumbers: [],
				credits: null,
				addLink: () => {
					if (this.WebItem.Expired || this.WebItem.Deleted) return true;
					return this.WebItem?.Audiences.includes("Everyone") || this.WebItem?.Audiences.includes("Parents");
				},
				maxLength: () => {
					if (this.SMS.addLink()) return 158 - this.SMS.itemLink().length;
					return 158;
				},
				itemLink: () => {
					return ` hartismere.com/${this.WebItem?.Id}`;
				}
			},
			AdditionalText: {
				enabled: false,
				text: ""
			},
			ReturnsDetails: "", // "" = none, "simple" or "google.form"
			ReturnsData: null, // JSON of recorded returns

			Log: []
		};
	},
	provide() {
		return {
			IO: async (options) => this.IO(options)
		};
	},
	created: async function () {
		this.Created = false;
		document.documentElement.classList.add("loading");
		let _response = null;
		//Load HartisMail
		try {
			_response = await this.$root.IO({
				url: " https://www.hartismere.com/!/vuebuild",
				data: {
					fetch: "webitem",
					id: location.search.substr(1)
				}
			});
			if (_response?.data?.WebItem) {
				this.WebItem = _response?.data?.WebItem;
				document.title = `HartisMail3 · ${this.WebItem.PageTitle || this.WebItem.Title}`;
			} else {
				if (confirm("Please go to the school website first..."))
					location.replace(
						"https://www.hartismere.com/Staff/Staff-Handbook/Computer-and-website-services/Hartismail-v30"
					);
			}
		} catch (err) {
			alert("Something is not right. Try again maybe?");
			console.error("Load HartisMail", err);
			return;
		}

		try {
			//Load Students
			_response = await this.IO({
				method: "get",
				url: "https://apps.hartismere.com/q/api.aspx?q=students&o=json"
			});

			for (let user of _response?.data?.sort((a, b) => {
				if (a.LoginId?.toUpperCase() > b.LoginId?.toUpperCase()) return 1;
				else if (a.LoginId?.toUpperCase() < b.LoginId?.toUpperCase()) return -1;
				else return 0;
			})) {
				if (user.SID) {
					delete user.Classes;
					delete user.Groups;
					this.People.push(user);
				} else {
					console.error("Bad Student", user);
				}
			}
		} catch (err) {
			alert("Something is not right. Try again maybe?");
			console.error("Load Students", err);
			return;
		}

		try {
			//Load SID Links
			_response = await this.IO({ method: "get", url: "https://www.hartismere.com/!/account/sidlinks/json" });
			this.SID_LINKS = _response?.data?.SidLinks;

			let _hartisMailCount = 0;
			for (let user of this.SID_LINKS) {
				for (let sid of user.ChildSids) {
					let _student = this.People.filter((v) => sid == v.SID)[0];
					if (!_student) {
						continue;
					}
					if (!_student.HartisMails) {
						_student.HartisMails = [];
					}
					_student.HartisMails.push(user);
					_hartisMailCount++;
				}
			}
		} catch (err) {
			alert("Something is not right. Try again maybe?");
			console.error("Load SID Links", err);
			return;
		}

		try {
			//Load Classes
			_response = await this.IO({
				method: "get",
				url: "https://apps.hartismere.com/q/api.aspx?q=classes&o=json"
			});
			this.Classes = _response?.data
				.filter((v) => v.StudentCodes.length)
				.sort((a, b) => {
					return a.Code.localeCompare(b.Code, "en", { numeric: true });
					if (a.Code < b.Code) return -1;
					if (a.Code > b.Code) return 1;
					return 0;
				});
		} catch (err) {
			alert("Something is not right. Try again maybe?");
			console.error("Load Classes", err);
			return;
		}

		this.SAVE_TIMER = null;

		await this.Load();
		window.setInterval(() => {
			this.Load();
		}, 180000);

		document.documentElement.classList.remove("loading");

		this.LOG_TIMER = window.setInterval(async () => {
			if (this.View != "process") return;
			let _response = await this.IO({ data: { id: this.WebItem.Id } });
			this.Log = _response?.data?.Hartismail?.Log;
		}, 8000);

		console.log("Created finished");
		this.Created = true;
	},
	mounted: async function () { },
	unmounted: function () {
		window.clearInterval(this.LOG_TIMER);
	},
	computed: {
		SelectedPeople: function () {
			return this.People.filter((v) => v.selected);
		},
		HartismailPeople: function () {
			return this.SelectedPeople.filter((v) => v.HartisMails);
		},
		NonHartismailPeople: function () {
			return this.SelectedPeople.filter((v) => !v.HartisMails);
		},
		HartismailAddresses: function () {
			var _result = [];
			if (this.People.filter((v) => v.resendSelected).length > 0) {
				console.log("have resendSelected", this.People.filter((v) => v.resendSelected).length);
				_result = this.People.filter((v) => v.HartisMails && v.resendSelected)
					.flatMap((v) => v.HartisMails)
					.map((v) => v.Email);
			} else {
				_result = this.HartismailPeople.flatMap((v) => v.HartisMails).map((v) => v.Email);
			}
			_result = [...new Set(_result)];
			return _result;
		},
		SMSNumbers: function () {
			return [
				...new Set(
					this.HartismailPeople.flatMap((v) => v.HartisMails.map((x) => x.TextAlert).filter((x) => x > 0))
				)
			];
		},
		NonSMSPeople: function () {
			return this.SelectedPeople.filter((v) => v.HartisMails?.filter((x) => x.TextAlert).length == 0);
		},
		AddPageLink: function () {
			if (this.WebItem.Audiences.length == 0) return -1;
			if (this.WebItem.Audiences.includes("Everyone") || this.WebItem.Audiences.includes("Parents")) return 1;
			return 0;
			return (
				(this.WebItem.Audiences.includes("Everyone") || this.WebItem.Audiences.includes("Parents")) &&
				!this.WebItem.Hidden
			);
		},
		ItemText: function () {
			const copy = document.createElement("div");
			copy.innerHTML = this.WebItem.Text.replace(/\n/g, " ")
				.replace(/<img.*?>/gm, "")
				.replace(/[\t\n]+/g, "");
			const tags = {
				B: ["**", "**", 1], // [<prefix>, <postfix>, <sequence-number> ]
				I: ["*", "*", 2],
				BR: ["", "\n", 3],
				H1: ["##", "\n", 4],
				H2: ["##", "\n", 5],
				H3: ["#", "\n", 6],
				H4: ["#", "\n", 7],
				P: ["\n", "\n", 8],
				LI: ["", "\n", 9],
				UL: ["\n", "\n", 10],
				TD: ["", "\t", 11],
				TH: ["", "\t", 12],
				DIV: ["", "\n", 13]
			};
			[...copy.querySelectorAll(Object.keys(tags).join(","))]
				.sort((a, b) => tags[a.tagName][2] - tags[b.tagName][2])
				.forEach((e) => {
					const [a, b] = tags[e.tagName];
					if (e.tagName == "BR") e.outerHTML = a + b;
					else e.innerHTML = (e.matches("TD:first-child") ? "\n" : a) + e.innerHTML + b;
				});
			if (this.AddPageLink >= 0) {
				copy.innerHTML =
					`View this email on our website: www.hartismere.com/${this.WebItem.Id} ${this.AddPageLink == 0 ? "(Access required)" : ""
					} \n\n------------------------------\n` + copy.innerHTML;
			}

			if (this.ReturnsDetails == "simple")
				copy.innerHTML +=
					`\n\n------------------------------\n\n` +
					`This Hartismail includes a return - you can send a return to us online here...\n` +
					`www.hartismere.com/${this.WebItem.Id}`;
			else if (this.ReturnsDetails != "")
				copy.innerHTML +=
					`\n\n------------------------------\n\n` +
					`This Hartismail includes a return - you can send a return to us using this Google Form...\n` +
					this.ReturnsDetails;

			return copy.innerText.replace(/^ */gm, "");

			let div = document.createElement("div");
			div.innerHTML = this.WebItem.Text;
			let _result = div.innerText;
			div.remove();
			return _result;
		},
		ItemHTML: function () {
			return `
				<div style="font-family: 'Century Gothic','Segoe UI',Canadra,Verdana,'Lucida Sans Unicode',sans-serif; font-size:18px;color: #2b4687;">
					${this.AddPageLink >= 0
					? `<p style="font-size: 0.85em;"><a href="${this.WebItem.UrlFull
					}">View this email on our website</a> ${this.AddPageLink == 0 ? "(Access required)" : ""
					}</p><hr />`
					: ""
				}
					${this.WebItem.Text.replace(/<figure.*?>(.*?)<\/figure>/gm, "$1")
					.replace(/<img\b/gm, `<img style="max-width: 80%; display: block; margin: 0 auto;"`)
					.replace(/<table\b/gm, `<table style="border-collapse:collapse"`)
					.replace(/<td\b/gm, `<td style="padding:2px;border:1px dotted #2b4687;"`)}
					${this.ReturnsDetails == "simple"
					? `<hr>This Hartismail includes a return - you can <a href="${this.WebItem.UrlFull}">send a return to us online here</a>...`
					: this.ReturnsDetails != ""
						? `<hr>This Hartismail includes a return - you can <a href="${this.ReturnsDetails}">send a return to us using this Google Form</a>...`
						: ""
				}
				</div>`
				.replace(/\bsrc=\"\/\//, 'src="https://')
				.replace(/\bhref=\"\/\//, 'href="https://')
				.replace(/\bsrc=\"\//gm, 'src="https://www.hartismere.com/')
				.replace(/\bhref=\"\//gm, 'href="https://www.hartismere.com/')
				.replace(/\t/g, "");
			//.replace(/\n/g, " ");
		},
		ReturnsValidity: function () {
			return (
				this.ReturnsDetails === "" ||
				this.ReturnsDetails === "simple" ||
				new RegExp(/(https:\/\/docs\.google\.com\/forms\/)|(https:\/\/forms\.gle\/)/).test(this.ReturnsDetails)
			);
		}
	},
	watch: {
		SelectedPeople: {
			handler: function () {
				this.DelaySave();
			},
			deep: true
		},
		AdditionalEmailAddresses: function () {
			this.DelaySave();
		},
		SMS: {
			handler: function () {
				this.DelaySave();
			},
			deep: true
		},
		AdditionalText: {
			handler: function () {
				this.DelaySave();
			},
			deep: true
		},
		ReturnsDetails: function () {
			this.DelaySave();
		},
		View: function (v) {
			if (v == "sms") this.GetSMS();
			for (var cls of [...document.documentElement.classList]) {
				if (cls.indexOf("view-") == 0) document.documentElement.classList.remove(cls);
			}
			document.documentElement.classList.add("view-" + v);
		}
	},
	methods: {
		HandleSend: async function () {
			await this.Save();
			let _response = await this.IO({ data: { id: this.WebItem.Id, action: "send" } });
			this.Log = _response?.data?.Hartismail?.Log;
		},
		DelaySave: function () {
			if (!this.Created) return;
			console.log("Delay save...");
			if (this.SAVE_TIMER) window.clearTimeout(this.SAVE_TIMER);
			this.SAVE_TIMER = window.setTimeout(async () => {
				await this.Save();
			}, 2500);
		},
		GetSMS: async function () {
			let _credits = await this.IO({ data: { action: "smscredits" } });
			this.SMS.credits = _credits?.data?.credits;
		},
		Save: async function () {
			if (!this.Created) return;
			console.log("saving");
			var hartisMailData = {
				id: this.WebItem.Id,
				action: "save",
				subject: this.WebItem.Title,
				html: this.ItemHTML,
				text: this.ItemText,
				students: this.SelectedPeople.map((v) => v.AdmissionNo).join(),
				additionalemailaddresses: this.AdditionalEmailAddresses.join(),
				hartismailaddresses: this.HartismailAddresses.join(),
				attachments: this.WebItem.Documents.map((v) => `${v.Title}|${v.PDF || v.UID}`).join("\n"),
				sms: this.SMS.enabled,
				smstext: this.SMS.text,
				//smsnumbers: this.SMSNumbers,
				smsadditionalnumbers: this.SMS.additionalNumbers.join("|"),
				smsaddlink: this.SMS.addLink(),
				addadditionaltext: this.AdditionalText.enabled,
				additionaltext: this.AdditionalText.text,
				returnsDetails: this.ReturnsValidity ? this.ReturnsDetails : ""
			};
			if (this.SMS.enabled) {
				hartisMailData.smsnumbers = this.SMSNumbers;
			}
			let _response = await this.IO({
				data: hartisMailData
			});
			this.Log = _response?.data?.Hartismail?.Log;
		},
		Load: async function () {
			console.log("loading...");

			let _response = await this.IO({
				data: {
					id: this.WebItem.Id,
					action: "load"
				}
			});
			if (_response?.data?.success) {
				for (let student of _response?.data?.Hartismail?.Students) {
					let _person = this.People.filter((v) => v.AdmissionNo == student)?.[0];
					if (_person) _person.selected = true;
				}
				this.AdditionalEmailAddresses = _response?.data?.Hartismail?.AdditionalEmailAddresses;
				this.Log = _response?.data?.Hartismail?.Log;
				this.SMS.enabled = _response?.data?.Hartismail?.SMS;
				this.SMS.text = _response?.data?.Hartismail?.SMSText || "";
				this.SMS.additionalNumbers = _response?.data?.Hartismail?.SMSAdditionalNumbers;
				this.AdditionalText.enabled = _response?.data?.Hartismail?.AddAdditionalText || false;
				this.AdditionalText.text = _response?.data?.Hartismail?.AdditionalText || "";
				this.ReturnsDetails = _response?.data?.Hartismail?.ReturnsDetails;
				this.ReturnsData = _response?.data?.Hartismail?.ReturnsData || null;
			}
		},
		IO: async function (options) {
			if (!options.url) options.url = "https://hartismail.hartismere.com/io.aspx";
			options.withCredentials = true;
			if (options.data) {
				if (!(options.data instanceof FormData)) {
					let fd = new FormData();
					for (let v of Object.keys(options.data)) fd.append(v, options.data[v]);
					options.data = fd;
				}
				options.method = options.method || "post";
				options.headers = { "Content-Type": "multipart/form-data" };
			} else {
				options.method = options.method || "post";
			}

			try {
				var response = await axios(options);
			} catch (err) {
				if (err.message != "cancelled") {
					alert(err.message);
				}
				console.error(err);
			}
			if (response?.data) {
				if (response.data.error) {
					if (response.data.stackTrace) console.error(response.data.stackTrace);
					alert("Error: " + response.data.error);
				}
			}
			return response;
		}
	}
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@400;700&display=swap");

:root {
	--header-height: 90px;
	--footer-height: 30px;
	--bgcolor: #fff0eb;
	font: 19px/1.2 "Assistant", sans-serif;
}

* {
	box-sizing: border-box;
	user-select: none;
}

textarea,
button,
input {
	font-family: "Assistant", sans-serif;
	font-size: 1em;
}

html.loading {
	filter: blur(2px);
	pointer-events: none;
	cursor: wait;
}

html {
	height: 100%;
}

body {
	background-color: var(--bgcolor);
	margin: 0;
}

label,
button {
	user-select: none;
}

input[type="checkbox"] {
	width: 0.9em;
	height: 0.9em;
	vertical-align: text-top;
}

textarea {
	border-radius: 12px;
	padding: 2px 4px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0.6rem 0;
}

.error {
	color: red;
}

div#app {
	position: relative;
	height: 100%;
}

header {
	height: var(--header-height);
	overflow: hidden;
	position: relative;
	white-space: nowrap;
	overflow: hidden;
	position: sticky;
	top: 0;
	background-color: var(--bgcolor);
	z-index: 2;
}

header:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(100% - 12px);
	border-bottom: 1px dotted #888;
	pointer-events: none;
}

header>img {
	float: left;
	width: min(70px, 10vw);
}

header h2 {
	display: inline-block;
	margin: 0px 12px;
}

header button {
	margin: 4px 0.3em;
	font-size: min(19px, 2vw);
}

header button.selected {
	font-weight: bold;
}

header button.selected>i {
	font-weight: bold;
}

header>* {
	vertical-align: middle;
}

footer {
	height: var(--footer-height);
	overflow: hidden;
}

::-webkit-scrollbar {
	width: 14px;
}

::-webkit-scrollbar-track {
	background: #f9dfd6;
	border-radius: 12px;
	margin: 6px;
}

::-webkit-scrollbar-thumb {
	background: #e6b9aa;
	border-radius: 24px;
	min-height: 10%;
}

::-webkit-scrollbar-thumb:hover {
	background: #d6a99a;
}

@media print {
	header {
		display: none;
	}

	:root .no-print {
		display: none;
	}

	body {
		background-color: #fff;
	}
}
</style>
