<template>
	<div id="Process">
		<div v-if="AllEmailAddress.length > 0" class="recipients">
			<p>
				<i class="fa-light fa-triangle-exclamation"></i>
				<strong> this data is protected by GDPR</strong>
			</p>
			<hr />
			<ul>
				<li v-for="r in AllEmailAddress" :key="r">
					{{ r }}
				</li>
				<template v-if="SMS.enabled">
					<hr />
					<li v-for="r in AllSMS" :key="r">0{{ r }}</li>
				</template>
			</ul>
		</div>
		<p>Selected students: {{ SelectedPeople.length }}</p>
		<p>Students with registered Hartismails: {{ HartismailPeople.length }}</p>
		<p>Additional email recipients: {{ AdditionalEmailAddresses.length }}</p>
		<p>Total emails to send: {{ AllEmailAddress.length }}</p>
		<hr />
		<template v-if="SMS.enabled">
			<p>Students with registered SMS text alert numbers: {{ SMSNumbers.length }}</p>
			<p>Additional SMS text alert recipients: {{ AdditionalNumbers.length }}</p>
			<p>Total SMS text alerts to send: {{ AllSMS.length }}</p>
			<hr />
		</template>
		<p>
			<i>
				<label>
					<input type="checkbox" v-model="Checks[0]" />
					I have checked that the item text is spelled correctly and is accurate (especially dates)
				</label>
			</i>
		</p>
		<p>
			<i>
				<label>
					<input type="checkbox" v-model="Checks[1]" />
					I have carefully selected the recipients for this item so that it is sent to the correct people.
				</label>
			</i>
		</p>
		<p>
			<i>
				<label>
					<input type="checkbox" v-model="Checks[2]" />
					I have checked that this item hasn't already been emailed to the same recipients recently.
				</label>
			</i>
		</p>
		<p v-if="SMS.enabled">
			<i>
				<label>
					<input type="checkbox" v-model="Checks[3]" />
					This is an urgent critical message that is appropriate to send via SMS.
				</label>
			</i>
		</p>
		<p>
			<button
				style="font-size: larger"
				:disabled="
					Checks.filter((v) => !v).length > 0 ||
					SendPressed ||
					AllEmailAddress.length == 0 ||
					SMS.text.length > SMS.maxLength()
				"
				@click="HandleSend"
			>
				<i class="fa-light fa-paper-plane-top"></i> Send...
			</button>
			&nbsp;
			<template v-if="SendPressed">Ok - Check the Log in a few moments for progress...</template>
		</p>
		<p style="font-size: smaller">
			NB - some parents may have changed their email address
			<template v-if="SMS.enabled">or SMS mobile number</template> and not updated their account, so will not
			receive this message. <i class="fa-light fa-face-confused"></i>
		</p>
		<hr />
		<div class="log" v-if="Log?.length">
			<h2>Log</h2>
			<div>
				<template v-for="l in Log" :key="l">
					<p>
						<span v-html="l" />
						<button v-if="false && l?.indexOf('Sending to: ') > -1" @click="HandleResend(l)">Resend</button>
					</p>
				</template>
			</div>
			<button @click="$emit('clear-log')">Clear log</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "process",
	components: {},
	props: {
		People: { type: Array, required: true },
		SelectedPeople: { type: Array, required: true },
		HartismailPeople: { type: Array, required: true },
		AdditionalEmailAddresses: { type: Array, required: true },
		HartismailAddresses: { type: Array, required: true },

		SMSNumbers: { type: Array, required: true },
		AdditionalNumbers: { type: Array, required: true },

		WebItem: { type: Object, required: true },
		SMS: { type: Object, required: true },
		Log: { type: Array }
	},
	data: function () {
		return {
			Checks: this.SMS.enabled ? [false, false, false, false] : [false, false, false],
			SendPressed: false
		};
	},
	computed: {
		AllEmailAddress: function () {
			return [...new Set([...this.HartismailAddresses, ...this.AdditionalEmailAddresses])];
		},
		AllSMS: function () {
			return [
				...new Set([
					...this.SMSNumbers,
					...this.AdditionalNumbers.map((v) =>
						parseInt(v.match(/0?7\d{3}\s?\d{6}/)?.[0]?.replace(/\s/gm, ""))
					)
				])
			];
		}
	},
	methods: {
		HandleSend: async function () {
			this.SendPressed = true;
			this.$emit("send");
		},
		HandleClearAllResends: function () {
			this.People.map((v) => (v.resendSelected = false));
		},
		HandleResend: async function (v) {
			this.HandleClearAllResends();
			var _emails = v
				.replace(/.*?Sending to: /gm, "")
				.split(",")
				.map((v) => v.trim());
			//console.log(_emails);
			for (let email of _emails) {
				for (let peep of this.People) {
					if (peep.HartisMails?.map((h) => h.Email)?.includes(email)) {
						peep.resendSelected = true;
					}
				}
			}
			await this.$nextTick();
			console.log(this.HartismailAddresses);
			this.$emit("send");
		}
	}
};
</script>

<style>
#Process {
	margin: 2% 4%;
}

#Process div.recipients {
	float: right;
	position: relative;
	background-color: #fff;
	border-radius: 12px;
	max-height: 200px;
	font-size: min(0.85em, 2vw);
	padding: 5px;
	border: 2px solid #888;
	overflow-y: auto;
	margin-left: 24px;
}

#Process div.recipients * {
	user-select: text;
}

#Process div.recipients ul {
	list-style-type: none;
	position: relative;
	padding: 0;
	margin: 0;
}

#Process div.recipients li {
	margin: 2px;
	padding: 1px;
	white-space: nowrap;
}

/* width */
#Process div.recipients::-webkit-scrollbar {
	width: 8px;
}

#Process div.log > div {
	white-space: pre-wrap;
	background-color: #fff;
	border-radius: 12px;
	max-width: 100%;
	font-size: 0.9em;
}

#Process div.log > div > p {
	padding: 8px;
	margin: 0;
}

#Process div.log > div > p:nth-child(even) {
	background-color: #dedddd;
}
</style>
