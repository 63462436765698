<template>
	<div id="returns">
		<div>
			<label><input type="radio" v-model="Type" :value="0" /> None</label>
			<div v-if="Type == 0"></div>
			<hr />
		</div>
		<div>
			<label
				><input
					type="radio"
					v-model="Type"
					:value="1"
					:disabled="!webItem.Audiences.includes('Everyone') && !webItem.Audiences.includes('Parents')"
				/>
				Simple
			</label>
			<i>People will see a form on the school website allowing them to reply to this Hartismail.</i>
			<p v-if="!webItem.Audiences.includes('Everyone') && !webItem.Audiences.includes('Parents')">
				The website item needs to be viewable by parents to enable this option...
			</p>
			<div>
				<template v-if="returnsData">
					<h2>Returns...</h2>
					<table>
						<tr>
							<th>From</th>
							<th>Student</th>
							<th>Message</th>
							<th>Date</th>
						</tr>
						<template v-for="m in returnsData" :key="m">
							<tr>
								<td>{{ m.from }}<br />{{ m.email }}</td>
								<td
									v-html="
										Object.keys(m.ChildSIDsAndNames)
											.map((sid) => m.ChildSIDsAndNames[sid])
											.join('<br />')
									"
								></td>
								<td>
									{{ m.Message }}
								</td>
								<td>
									{{ m.Date_Extra.Short }}
								</td>
							</tr>
						</template>
					</table>
				</template>
			</div>
			<hr />
		</div>
		<div>
			<label><input type="radio" v-model="Type" :value="2" /> Google Form</label>
			<div v-if="Type == 2">
				<p>
					Copy and paste the website address of the <b>viewable</b> page of the Google Form.<br />
					<i>This usually ends with "/viewform"</i>
				</p>
				<p>
					Form Url:
					<input
						placeholder="e.g. https://docs.google.com/forms/d/e/abc123.../viewform"
						type="text"
						v-model.lazy="GoogleFormId"
					/>
				</p>
				<p :class="{ error: GoogleFormFeedback === false }">
					<template v-if="GoogleFormFeedback === true"> ...looks OK. </template>
					<template v-else-if="GoogleFormFeedback === false">
						...does not look like a Google Form URL.
					</template>
					<template v-else> Copy and Paste a Google Form URL here... </template>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "returns",
	props: {
		modelValue: {
			type: String,
			required: true
		},
		returnsData: Array,
		webItem: Object
	},
	emits: ["update:modelValue"],
	inject: ["IO"],
	data: function () {
		return {
			Type: 0,
			GoogleFormFeedback: null,
			GoogleFormId: ""
		};
	},

	mounted: async function () {
		if (
			!this.webItem.Audiences.includes("Everyone") &&
			!this.webItem.Audiences.includes("Parents") &&
			this.Type == 1
		)
			this.Type = 0;
	},
	computed: {},
	watch: {
		modelValue: {
			handler: function (v) {
				if (!v) this.Type = 0;
				else if (v == "simple") this.Type = 1;
				else if (v) {
					this.Type = 2;
					this.GoogleFormId = v.replace(/google-form/, "");
				}
			},
			immediate: true,
			deep: true
		},
		Type: function (v) {
			console.log("Type");
			if (v == 0) this.$emit("update:modelValue", "");
			else if (v == 1) this.$emit("update:modelValue", "simple");
			else if (v == 2) this.$emit("update:modelValue", this.GoogleFormId || "google-form");
		},
		GoogleFormId: {
			handler: async function (v) {
				console.log("Fetching Google Form Details");

				this.GoogleFormFeedback = null;

				if (v == "") {
					return;
				}

				this.GoogleFormFeedback = new RegExp(
					/(https:\/\/docs\.google\.com\/forms\/)|(https:\/\/forms\.gle\/)/
				).test(this.GoogleFormId);
				this.$emit("update:modelValue", v);
			},
			immediate: true
		}
	},

	methods: {}
};
</script>

<style>
#returns {
	margin: 2% 4%;
}

#returns input[type="text"] {
	width: 500px;
}

#returns table {
	font-size: 0.85em;
	border-collapse: collapse;
	border: 1px solid #888;
}

#returns td {
	padding: 8px;
	border: 1px dotted #888;
	vertical-align: top;
}
</style>
