<template>
	<div id="WebItem" v-if="WebItem">
		<div>
			<p v-if="WebItem.Audiences.includes('Everyone')">This website can be viewed by everyone.</p>
			<p v-else-if="WebItem.Audiences.length > 0">
				This website item can only be viewed by: {{ WebItem.Audiences.join(", ")
				}}<template v-if="!WebItem.Audiences.includes('Parents')">
					- but you can still send it as a Hartismail</template
				>.
			</p>
			<p v-else>No one can view this website item - but you can still send it as a Hartismail.</p>
			<p>
				It's set to expire:
				<span :class="{ error: WebItem.DateExpire_Extra.X }">{{ WebItem.DateExpire_Extra.PrettyPlus }}</span>
			</p>
			<p>
				View this item on the school website... <br />
				<a :href="WebItem.UrlFull" target="_blank">{{ WebItem.UrlFull }}</a>
			</p>
		</div>
		<hr />
		<div>
			<p>
				<label
					><input type="checkbox" v-model="AdditionalText.enabled" /> Add additional text? (e.g. "This is a
					reminder...")</label
				>
			</p>
			<p v-if="AdditionalText.enabled">
				<textarea v-model="AdditionalText.text"></textarea>
			</p>
		</div>

		<hr />
		<h2>Full HTML version...</h2>
		<div class="email">
			<h2>{{ WebItem.Title }}</h2>
			<p style="font-size: smaller; color: #668">From: HartisMail.NoReply@hartismere.com</p>
			<template v-if="AdditionalText.enabled && AdditionalText.text">
				<p v-html="AdditionalText.text.replace(/\n/gm, '<br />')"></p>
				<hr />
			</template>
			<div class="text" v-html="HTML"></div>
			<div class="docs" v-if="WebItem.Documents?.length">
				<hr />
				<h3>Attachments</h3>
				<template v-for="doc in WebItem.Documents" :key="doc">
					<p>
						<img :src="`https://www.hartismere.com/!/icon/${doc.UID}`" /> {{ doc.Title }}.{{
							(doc.PDF || doc.UID).split(".").reverse()[0]
						}}
					</p>
				</template>
			</div>
			<hr />
			<div class="footer">
				<div style="font-size: smaller; color: #888">
					This email has been sent to you automatically from Hartismere School.<br />
					<b>Please do not reply to this email.</b><br />
					We also post some HartisMails on our website at
					www.hartismere.com/Parents/Hartismails-and-letters<br />
					Manage your account settings at www.hartismere.com/account
				</div>
				<div style="font-size: small; color: #aaa">
					Emails and any files transmitted with them are confidential and intended solely for the use of the
					individual or entity to whom they are addressed. If you have received an email from Hartismere in
					error please notify the School. Please note that any views or opinions presented in Hartismere
					emails are solely those of the author and do not necessarily represent those of Hartismere School.
					The recipient should check received emails and any attachments for the presence of viruses.<br />
					Hartismere School accepts no liability for any damage caused by any virus transmitted by this email.
				</div>
				<div style="font-size: smaller; color: #666">
					<br />
					<img style="width: 100px" src="https://www.hartismere.com/assets/hartismere.com/logo2.png" /><br />
					Hartismere School<br />
					Castleton Way<br />
					Eye, Suffolk. IP23 7BL <br />United Kingdom <br />01379 870315<br />
					www.hartismere.com
				</div>
			</div>
		</div>

		<hr />

		<h2>Plain text version...</h2>
		<div class="plain">
			{{ WebItem.Title }}
			{{ "\n\n" }}
			<template v-if="AdditionalText.enabled && AdditionalText.text">
				{{ AdditionalText.text }}
				{{ "\n\n------------------------------\n" }}
			</template>
			{{ Text }}
			<template v-if="WebItem.Documents?.length">
				{{ "\n\n------------------------------\n" }}
				{{ "\nAttachments:\n" }}
				<template v-for="doc in WebItem.Documents" :key="doc">
					{{ doc.Title }}.{{ (doc.PDF || doc.UID).split(".").reverse()[0] }}
					{{ "\n" }}
				</template>
			</template>
			{{
				"\n\n------------------------------\n" +
				"This email has been sent to you automatically from Hartismere School.\n" +
				"*** Please do not reply to this email. ***\n" +
				"We also post some HartisMails on our website at www.hartismere.com/Parents/Hartismails-and-letters\n" +
				"Manage your account settings at www.hartismere.com/account\n" +
				"------------------------------\n" +
				"Emails and any files transmitted with them are confidential and intended solely for the use of the individual or entity to whom they are addressed. If you have received an email from Hartismere in error please notify the School. Please note that any views or opinions presented in Hartismere emails are solely those of the author and do not necessarily represent those of Hartismere School. The recipient should check received emails and any attachments for the presence of viruses."
			}}
		</div>
	</div>
</template>

<script>
export default {
	name: "preview",
	props: {
		WebItem: { type: Object, required: true },
		HTML: { type: String, required: true },
		Text: { type: String, required: true },
		AdditionalText: Object
	},
	data: function () {
		return {};
	},
	mounted: async function () {},
	computed: {},
	watch: {}
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Fredoka&display=swap");

#WebItem > div,
#WebItem > h2 {
	margin: 3% 8%;
}

#WebItem textarea {
	min-width: 100%;
	max-width: 100%;
	min-height: 100px;
	border-radius: 4px;
}

#WebItem div.email {
	font-family: "Century Gothic", "Segoe UI", Canadra, Verdana, "Lucida Sans Unicode", sans-serif;
	font-size: 18px;
	color: #2b4687;
	padding: 12px 24px;
	background-color: #fff;
	border-radius: 12px;
	box-shadow: 0 0 17px 0 #444;
}

#WebItem div.email h1 {
	margin: 8px 0;
}

#WebItem div.email div.docs p {
	overflow: auto;
}

#WebItem div.email div.docs p img {
	float: left;
	height: 60px;
	width: 60px;
	object-fit: cover;
	vertical-align: middle;
	margin-right: 12px;
}

#WebItem div.email div.footer div {
	margin: 8px 0;
}

#WebItem div.plain {
	white-space: pre-wrap;
	font-family: sans-serif;
	font-size: 15px;
	padding: 12px 24px;
	background-color: #fff;
	border-radius: 12px;
	box-shadow: 0 0 17px 0 #444;
}
</style>
