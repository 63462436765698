<template>
	<div id="NonHartisMail">
		<button @click="HandlePrint" class="no-print" style="float: right">
			<i class="fa-light fa-print"></i> Print
		</button>
		<h3>Website item details</h3>
		<p>Title: {{ WebItem.PageTitle || WebItem.Title }}</p>
		<template v-if="!WebItem.Audiences.includes('Everyone') && !WebItem.Audiences.includes('Parents')">
			<p v-if="!SMS.enabled">Parents cannot view the website item. This is usually ok.</p>
			<p v-else-if="SMS.enabled">
				<span class="error">Parents cannot view the website item</span>, so we won't send a link to it in the
				SMS text alert. This is usually ok, but make sure the SMS text alert message has sufficient detail, or
				invites parents to check their email.
				<template v-if="WebItem.Documents.length > 0">Especially as you've included attachments.</template>
			</p>
		</template>
		<template v-else-if="WebItem.Flags.Expired">
			<p v-if="!SMS.enabled">The website item has expired. This is probably ok.</p>
			<p v-else-if="SMS.enabled">
				<span class="error">The website item has expired</span>, so we won't send a link to it in the SMS text
				alert. This is usually ok, but make sure the SMS text alert message has sufficient detail, or invites
				parents to check their email.
				<template v-if="WebItem.Documents.length > 0">Especially as you've included attachments.</template>
			</p>
		</template>

		<hr />
		<h3>Hartismail details</h3>

		<template v-if="SelectedPeople.length == 0">
			<p>No students have been selected.</p>
		</template>
		<template v-else-if="NonHartismailPeople.length > 0">
			<p>
				The following selected students do not have a registered Hartismail address
				<template v-if="SMS.enabled">or SMS mobile number</template>:
			</p>
			<people :People="NonHartismailPeopleSorted" :viewablePeople="NonHartismailPeopleSorted" :ReadOnly="true" />
		</template>
		<template v-else>
			<p>All selected students have a registered Hartismail address.</p>
		</template>
		<template v-if="SMS.enabled && (NonSMSPeople.length > 0 || SMS.text.length > SMS.maxLength())">
			<hr />
			<h3>SMS Text Alert details</h3>
			<p v-if="SMS.text.length > SMS.maxLength()" class="error">STOP: Your SMS message is too long.</p>
			<p v-if="SMSNumbers.length > 100" class="error">
				You're sending over 100 SMS text alerts. This may be expensive. Remember: SMS text alerts are for
				critical and urgent messages only.
			</p>
			<template v-if="NonSMSPeople.length > 0">
				<p>The following selected students do not have a registered SMS text alert number:</p>
				<people :People="NonSMSPeople" :viewablePeople="NonSMSPeople" :ReadOnly="true" />
			</template>
		</template>
		<template v-if="WebItem?.Documents?.length > 0">
			<hr />
			<h3>Attachments</h3>
			<p>Attachments over 20Mb cannot be sent via email and are shown in red...</p>
			<ul>
				<li v-for="a in WebItem.Documents" :key="a" :class="{ error: Size(a.FileSize) > 20 }">
					{{ a.Title }}.{{ (a.PDF || a.UID).split(".").reverse()[0] }} ({{ Size(a.FileSize) }} Mb)
					<template v-if="a.Type != 'application/pdf' && !a.PDF">
						<br /><span class="error" style="font-size: smaller">
							This is not a PDF file - which may be ok. If it's a Word document, perhaps the website
							hasn't finished converting it yet?
						</span>
					</template>
				</li>
			</ul>
		</template>
		<template v-if="!ReturnsValidity">
			<hr />
			<p class="error">The address of the Returns Google Form does not look valid.</p>
		</template>
	</div>
</template>

<script>
import people from "@/components/people.vue";

export default {
	name: "problems",
	components: { people },
	props: {
		People: { type: Array, required: true },
		SelectedPeople: { type: Array, required: true },
		NonHartismailPeople: { type: Array, required: true },
		NonSMSPeople: { type: Array, required: true },
		SMSNumbers: { type: Array, required: true },
		WebItem: { type: Object, required: true },
		SMS: { type: Object, required: true },
		ReturnsValidity: Boolean
	},
	computed: {
		NonHartismailPeopleSorted: function () {
			return [...this.NonHartismailPeople].sort((a, b) =>
				`${a.Reg} ${a.LastName} ${a.FirstName}`.localeCompare(`${b.Reg} ${b.LastName} ${b.FirstName}`, "en", {
					numeric: true
				})
			);
		}
	},
	methods: {
		Size: function (length) {
			return Math.ceil(length / 1024 / 1024, 1);
		},
		HandlePrint: function () {
			window.print();
		}
	}
};
</script>

<style>
#NonHartisMail {
	margin: 2% 4%;
}

#NonHartisMail div.people * {
	user-select: text;
}

#NonHartisMail ul {
	background-color: #fff;
	border-radius: 12px;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

#NonHartisMail ul li {
	margin: 4px;
	padding: 4px;
}
</style>
