<template>
	<div class="people" :class="{ readonly: ReadOnly }">
		<div class="head" v-if="!ReadOnly">
			<p>
				<button @click="HandleCopy" :disabled="SelectedPeople.length == 0">Copy selection</button>
				<template v-if="SelectedPeople.length > 0">
					<button :disabled="!CopyData" @click="HandlePaste(true)">Paste & overwrite</button>
					<button :disabled="!CopyData" @click="HandlePaste(false)">Paste & merge</button>
				</template>
				<template v-else>
					<button :disabled="!CopyData" @click="HandlePaste(false)">Paste selection</button>
				</template>
			</p>
			<p>
				Search: <input typre="text" v-model="SearchText" />
				<button :disabled="!SearchText" @click="SearchText = ''">
					<i class="fa-light fa-xmark-large"></i>
				</button>
			</p>
			<span>
				<i
					v-if="ViewablePeople.filter((v) => v.selected).length == 0"
					@click="HandleSelectAll"
					class="fa-regular fa-square"
				></i>
				<i
					v-else-if="ViewablePeople.filter((v) => v.selected).length == ViewablePeople.length"
					@click="HandleSelectAll"
					class="fa-regular fa-square-check"
				></i>
				<i v-else @click="HandleSelectAll" class="fa-regular fa-square-minus"></i>
			</span>
			<span @click="HandleSelectAll">Selected: {{ ViewablePeople.filter((v) => v.selected).length }}</span>
			<span>
				<strong>Total Selected: {{ People.filter((v) => v.selected).length }}</strong>
			</span>
			<button @click="HandleViewAllRecipients">View all recipients</button>
		</div>
		<div class="list">
			<ul>
				<li
					v-for="person in ViewablePeople"
					:key="person"
					:class="{ Selected: person.selected, HartisMail: person.HartisMails }"
					@click="if (!ReadOnly) person.selected = !person.selected;"
				>
					<template v-if="!ReadOnly">
						<i v-if="person.selected" class="fa-regular fa-square-check fa-fw"></i>
						<i v-else class="fa-regular fa-square fa-fw"></i>
					</template>
					<i v-if="person.HartisMails" class="fa-regular fa-envelope fa-fw"></i>

					<i v-else class="fa-fw"></i>

					<template v-if="sms">
						<i
							v-if="person.HartisMails?.filter((v) => v.TextAlert).length"
							class="fa-light fa-comment-sms fa-fw"
						>
						</i>
						<i v-else class="fa-fw"></i>
					</template>

					{{ person.FirstName }} {{ person.LastName }} ({{ person.Reg }})
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: "people",
	data: function () {
		return {
			CopyData: null,
			ViewablePeople: this.viewablePeople,
			SearchText: null
		};
	},
	props: {
		viewablePeople: { type: Array, required: true },
		People: { type: Array, required: true },
		ReadOnly: Boolean,
		sms: Boolean
	},
	mounted: function () {
		addEventListener("storage", this.HandleLocalStorage);
		this.HandleLocalStorage();
	},
	unmounted: function () {
		removeEventListener("storage", this.HandleLocalStorage);
	},
	computed: {
		SelectedPeople: function () {
			return this.People.filter((v) => v.selected);
		}
	},
	watch: {
		CopyData: function (v) {
			localStorage["hartismail3-copy-data"] = JSON.stringify(v);
		},
		viewablePeople: function (v) {
			this.ViewablePeople = v;
		},
		SearchText: function (searchText) {
			this.ViewablePeople = this.People.filter((v) =>
				`${v.FirstName} ${v.LastName} ${v.Reg}`.match(new RegExp(`\\b${searchText}`, "gmi"))
			);
			return;
			this.ViewablePeople = this.People.filter((v) => {
				for (let search of searchText.toLowerCase().split(" ")) {
					if (v.FirstName.toLowerCase().indexOf(search) > -1) return true;
					if (v.LastName.toLowerCase().indexOf(search) > -1) return true;
					if (v.Reg.toLowerCase().indexOf(search) > -1) return true;
				}
				return false;
			});
		}
	},
	methods: {
		HandleSelectAll: function () {
			if (this.ViewablePeople.filter((v) => v.selected).length == 0) {
				this.ViewablePeople.map((v) => (v.selected = true));
			} else if (this.ViewablePeople.filter((v) => v.selected).length == this.ViewablePeople.length) {
				this.ViewablePeople.map((v) => (v.selected = false));
			} else {
				this.ViewablePeople.map((v) => (v.selected = true));
			}
		},
		HandleViewAllRecipients: function () {
			this.ViewablePeople = this.People.filter((v) => v.selected);
		},
		HandleLocalStorage: function () {
			this.CopyData = JSON.parse(localStorage["hartismail3-copy-data"] || "null");
		},
		HandleCopy: function () {
			this.CopyData = this.SelectedPeople.map((v) => v.AdmissionNo);
		},
		HandlePaste: function (overwrite) {
			if (overwrite) this.SelectedPeople.map((v) => (v.selected = false));
			for (let adNo of this.CopyData) {
				let thisPerson = this.People.filter((v) => v.AdmissionNo == adNo)?.[0];
				if (thisPerson) thisPerson.selected = true;
			}
			this.CopyData = null;
			this.HandleViewAllRecipients();
		}
	}
};
</script>

<style>
div.people div.head {
	height: 132px;
	white-space: nowrap;
	overflow: hidden;
}

div.people div.head span {
	margin: 8px;
}

div.people div.list {
	height: calc(100% - 132px);
	overflow-y: auto;
	background-color: #fff;
	border-radius: 12px;
}

div div.people ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

div.people ul li {
	padding: 3px;
	margin: 3px;
	border-radius: 12px;
}

div.people:not(.readonly) ul li {
	cursor: pointer;
}

div.people:not(.readonly) ul li:hover {
	font-weight: bold;
}

div.people:not(.readonly) ul li.Selected {
	background-color: #e8e9ec;
}

div.people li i.fa-envelope,
div.people li i.fa-comment-sms {
	color: #e6b9aa;
}
</style>
