<template>
	<div id="sms">
		<h3>Please note:</h3>
		<p>There is a cost to the school for sending SMS text messages.</p>
		<p>Please use with discretion and only for urgent and critical messages.</p>
		<p>
			<label>Send SMS text message? <input type="checkbox" v-model="SMS.enabled" /></label>
		</p>
		<template v-if="SMS.enabled">
			<hr />
			<p>
				Message:
				<textarea :maxlength="SMS.maxLength()" v-model="SMS.text"></textarea>
				<span
					style="text-align: right; display: inline-block; width: 100%"
					:class="{ error: SMS.text.length > SMS.maxLength() }"
				>
					Characters remaining:
					{{ SMS.maxLength() - SMS.text.length }}
				</span>
			</p>
			<template v-if="!SMS.addLink()">
				<p>
					Normally we'd add a link to the website item so parents can view more details. But parents cannot
					view this item because it has expired, or they're not included in the Audiences section<template
						v-if="WebItem.Audiences.length > 0"
					>
						({{ WebItem.Audiences.join(", ") }}) </template
					>.
				</p>
				<p v-if="WebItem.Documents.length == 0">
					This may be ok if the SMS message contains all the relevant information, so it's probably safe to
					continue.
				</p>
				<p v-else>
					<span class="error">Caution:</span> the website item contains attachments which parents will not be
					able to access via the website (although they will receive the attachments in the Hartismail.) It's
					probably safe to continue, but consider inviting parents to check their email.
				</p>
			</template>
			<p v-else>
				The following text will be added to the SMS message so that people can view the details on the
				website:<br />
				<a target="_blank" :href="href">{{ SMS.itemLink() }}</a>
			</p>
		</template>
		<template v-if="SMS.credits">
			<hr />
			<h3>SMS system</h3>
			<p>Balance: {{ SMS.credits.balance }}</p>
			<p>Status: {{ SMS.credits.status }}</p>
		</template>
	</div>
</template>
<script>
export default {
	name: "sms",
	props: {
		SMS: { type: Object, required: true },
		WebItem: { type: Object, required: true }
	},
	created: function () {},
	mounted: function () {
		if (!this.SMS.text) this.SMS.text = this.WebItem.Title;
	},
	computed: {
		href: function () {
			return `https://www.hartismere.com/${this.WebItem.Id}`;
		}
	}
};
</script>
<style>
#sms {
	margin: 2% 4%;
}

#sms textarea {
	width: 100%;
	min-width: 100%;
	max-width: 100%;
	height: 100px;
}
</style>
