<template>
	<div id="Recipients">
		<people
			v-if="SelectedPeople.length > 0 && SelectedPeople.length <= 10"
			:People="SelectedPeople"
			:viewablePeople="SelectedPeople"
			:sms="sms"
			:ReadOnly="true"
		></people>
		<div v-else class="students">
			<h3>Count of selected students: {{ SelectedPeople.length }}</h3>
			<p>Count of selected students with HartisMails: {{ HartismailPeople.length }}</p>
		</div>
		<div class="additional">
			<p>Additional email recipients. One on each line.</p>

			<textarea v-model="AdditionalEmailAddresses" @input="HandleTextArea"></textarea>
			<div class="feedback error">
				<p v-for="f in Feedback" :key="f">{{ f }}</p>
			</div>
			<p>
				Also consider
				<a target="_blank" href="https://www.hartismere.com/31931"> everyone@hartismere.com etc... </a>
			</p>
		</div>
		<template v-if="sms">
			<hr />
			<p>Count of selected students with SMS text alert numbers: {{ SMSNumbers.length }}</p>

			<p>
				Additional SMS text recipient numbers. One on each line.
				<button :disabled="!StaffSMSNumbers" @click="HandleAddAllStaffClick">
					<template v-if="!StaffSMSNumbers">Hang on...</template>
					<template v-if="StaffSMSNumbers">Add all staff...</template>
				</button>
			</p>
			<textarea v-model="SMSAdditionalNumbers" @input="HandeTextArea2"></textarea>
			<div class="feedback error">
				<p v-for="f in Feedback2" :key="f">{{ f }}</p>
			</div>
		</template>
	</div>
</template>

<script>
import people from "@/components/people.vue";

export default {
	name: "recipients",
	components: { people },
	inject: ["IO"],
	props: {
		People: { type: Array, required: true },
		SelectedPeople: { type: Array, required: true },
		HartismailPeople: { type: Array, required: true },
		SMSNumbers: { type: Array, required: true },
		additionalEmailAddresses: { type: Array, required: true },
		smsadditionalnumbers: { type: Array, required: true },
		sms: Boolean
	},
	data: function () {
		return {
			AdditionalEmailAddresses: this.additionalEmailAddresses.join("\n"),
			SMSAdditionalNumbers: this.smsadditionalnumbers.join("\n"),
			Feedback: [],
			Feedback2: [],
			StaffSMSNumbers: null
		};
	},
	mounted: async function () {
		if (this.sms) {
			this.StaffSMSNumbers =
				(await this.IO({ data: { action: "staffsmsnumbers" } }))?.data?.StaffSMSNumbers ?? null;
		}
	},
	computed: {},
	watch: {
		/*
		additionalEmailAddresses: function (v) {
			this.AdditionalEmailAddresses = v.join("\n");
		},
		smsadditionalnumbers: function (v) {
			this.SMSAdditionalNumbers = v.join("\n");
		}
		*/
	},
	methods: {
		HandleTextArea: function () {
			this.Feedback = [];
			let goodEmails = [];

			for (let entry of this.AdditionalEmailAddresses.split("\n")) {
				if (!entry) continue;
				if (!entry.match(/^[\w\-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
					this.Feedback.push(`${entry} is not a valid email address`);
				} else if (goodEmails.map((v) => v.toLowerCase()).includes(entry.toLowerCase())) {
					this.Feedback.push(`You've already added ${entry}`);
				} else {
					goodEmails.push(entry);
				}
			}

			this.$emit("update-emails", goodEmails);
		},
		HandeTextArea2: function () {
			this.Feedback2 = [];
			let goodNumbers = [];

			for (let entry of this.SMSAdditionalNumbers.split("\n")) {
				if (!entry) continue;
				if (!entry.match(/0?7\d{3}\s?\d{6}/)) {
					this.Feedback2.push(`${entry} is not a valid mobile phone number`);
				} else if (
					goodNumbers
						.map((v) => parseInt(v.match(/0?7\d{3}\s?\d{6}/)?.[0]?.replace(/\s/gm, "")))
						.includes(parseInt(entry.match(/0?7\d{3}\s?\d{6}/)?.[0]?.replace(/\s/gm, "")))
				) {
					this.Feedback2.push(`You've already added ${entry}`);
				} else {
					goodNumbers.push(entry);
				}
			}

			this.$emit("update-numbers", goodNumbers);
		},
		HandleAddAllStaffClick: function () {
			for (var staff of this.StaffSMSNumbers) this.SMSAdditionalNumbers += `${staff}\n`;
			this.HandeTextArea2();
		}
	}
};
</script>

<style>
#Recipients {
	margin: 2% 4%;
}

div#Recipients div.people ul li i {
	display: inline-block;
}

#Recipients textarea {
	width: 100%;
	min-width: 100%;
	max-width: 100%;
	height: 120px;
}
</style>
