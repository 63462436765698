<template>
	<div id="Students" v-if="Classes.length && People.length">
		<navComponent :Classes="Classes" :People="People" @update="(e) => $emit('update', e)"></navComponent>
		<div class="margin"></div>
		<people :sms="sms" :People="People" :viewablePeople="viewablePeople" :ReadOnly="ReadOnly" />
	</div>
</template>

<script>
import navComponent from "@/components/nav.vue";
import people from "@/components/people.vue";

export default {
	name: "students",
	components: { navComponent, people },
	props: {
		viewablePeople: { type: Array, required: true },
		People: { type: Array, required: true },
		ReadOnly: Boolean,
		Classes: { type: Array, required: true },
		sms: Boolean
	}
};
</script>

<style>
html.view-students body {
	overflow: hidden;
}

div#Students {
	height: calc(100vh - var(--header-height) - var(--footer-height));
	font-size: min(19px, 2vw);
}

div#Students div.margin {
	position: relative;
	display: inline-block;
	width: 20px;
	height: 100%;
}

div#Students div.margin:after {
	content: "";
	position: absolute;
	border-right: 1px dotted #000;
	height: 100%;
	width: 3px;
}

div#Students nav {
	display: inline-block;
	width: 40%;
	height: 100%;
	vertical-align: top;
	padding: 0 12px;
}

div#Students div.people {
	display: inline-block;
	width: calc(60% - 20px - 12px);
	height: 100%;
	vertical-align: top;
}

div#Students div.people ul li i {
	display: inline-block;
}
</style>
