<template>
	<nav>
		<i
			v-if="Years.filter((v) => v.selected).length == 0"
			@click="Years.map((v) => (v.selected = true))"
			class="fa-regular fa-square"
		></i>
		<i
			v-else-if="Years.filter((v) => v.selected).length == Years.length"
			@click="Years.map((v) => (v.selected = false))"
			class="fa-regular fa-square-check"
		></i>
		<i v-else @click="Years.map((v) => (v.selected = true))" class="fa-regular fa-square-minus"></i>
		&nbsp;
		<h3>Years</h3>
		<div class="years">
			<template v-for="y of Years" :key="y">
				<label> <input type="checkbox" v-model="y.selected" /> {{ y.label }}</label>
			</template>
		</div>
		<div class="gender">
			<label><input type="checkbox" :checked="Gender.Girls" @input="HandleGenderInput('girls')" /> Girls </label>
			<label><input type="checkbox" :checked="Gender.Boys" @input="HandleGenderInput('boys')" /> Boys </label>
			<label><input type="checkbox" v-model="HasHartisMail" /> Has HartisMail </label>
		</div>
		<h3>Subjects</h3>
		<div class="subjects">
			<label v-for="y of Subjects" :key="y"><input type="checkbox" v-model="y.selected" /> {{ y.label }} </label>
		</div>
		<template v-if="ViewableClasses.length">
			<i
				v-if="ViewableClasses.filter((v) => v.selected).length == 0"
				@click="ViewableClasses.map((v) => (v.selected = true))"
				class="fa-regular fa-square"
			></i>
			<i
				v-else-if="ViewableClasses.filter((v) => v.selected).length == ViewableClasses.length"
				@click="ViewableClasses.map((v) => (v.selected = false))"
				class="fa-regular fa-square-check"
			></i>
			<i v-else @click="ViewableClasses.map((v) => (v.selected = true))" class="fa-regular fa-square-minus"></i>
			&nbsp;
			<h3>Classes</h3>
			<div class="classes">
				<label v-for="y of ViewableClasses" :key="y"
					><input type="checkbox" v-model="y.selected" /> {{ y.Code }}
				</label>
			</div>
		</template>
	</nav>
</template>

<script>
export default {
	name: "navComponent",
	props: {
		Classes: { type: Array, required: true },
		People: { type: Array, required: true }
	},
	data: function () {
		return {
			Years: [],
			Subjects: [],
			Gender: {
				Boys: true,
				Girls: true
			},
			HasHartisMail: false
		};
	},
	created: async function () {
		for (let y = 7; y <= 13; y++) {
			this.Years.push({ label: y, selected: false });
		}
		for (let subject of this.Classes?.map((v) => v.Subject).filter((v, i, a) => a.indexOf(v) == i)) {
			this.Subjects.push({ label: subject, selected: false });
		}

		this.Subjects.sort((a, b) => {
			if (a.label == "REG" || b.label == "REG") return 1;
			if (a.label < b.label) return -1;
			if (a.label > b.label) return 1;
			return 0;
		});
	},
	watch: {
		ViewablePeople: {
			handler: function (v) {
				this.$emit("update", this.ViewablePeople);
			},
			deep: true
		},
		SelectedSubjects: {
			handler: function (v) {
				for (let cls of this.Classes.filter((x) => x.selected && !v.includes(x.Subject))) {
					cls.selected = false;
				}
			},
			deep: true
		},
		SelectedYears: {
			handler: function (v) {
				if (v.length == 0) return;
				for (let cls of this.Classes.filter((x) => x.selected && !v.includes(x.Year))) {
					cls.selected = false;
				}
			},
			deep: true
		}
	},
	computed: {
		SelectedYears: function () {
			return this.Years.filter((x) => x.selected).map((v) => parseInt(v.label));
		},
		SelectedSubjects: function () {
			return this.Subjects.filter((x) => x.selected).map((v) => v.label);
		},
		ViewableClasses: function () {
			let _allYearClasses = this.Classes.filter((v) => this.SelectedSubjects.indexOf(v.Subject) > -1);
			if (!this.SelectedYears?.length) return _allYearClasses;
			return _allYearClasses.filter((v) => this.SelectedYears.includes(v.Year));
		},
		SelectedClasses: function () {
			return this.Classes.filter((v) => v.selected);
		},
		SelectedGenders: function () {
			let _result = [];
			if (this.Gender.Boys) _result.push("M");
			if (this.Gender.Girls) _result.push("F");
			return _result;
		},
		ViewablePeople: function () {
			return this.People.filter(
				(person) =>
					((person.HartisMails?.length > 0 && this.HasHartisMail) || !this.HasHartisMail) &&
					(this.SelectedYears.length == 0 || this.SelectedYears.includes(person.Year)) &&
					this.SelectedGenders.includes(person.Gender) &&
					(this.SelectedClasses.length == 0 ||
						this.SelectedClasses.flatMap((v) => v.StudentCodes).includes(person.AdmissionNo)) &&
					(this.SelectedSubjects.length == 0 ||
						this.ViewableClasses.filter((v) => this.SelectedSubjects.includes(v.Subject))
							.flatMap((v) => v.StudentCodes)
							.includes(person.AdmissionNo))
			);
		}
	},
	methods: {
		HandleGenderInput: function (v) {
			if (v === "boys") {
				this.Gender.Boys = !this.Gender.Boys;
				if (!this.Gender.Boys && !this.Gender.Girls) this.Gender.Girls = true;
			}
			if (v === "girls") {
				this.Gender.Girls = !this.Gender.Girls;
				if (!this.Gender.Boys && !this.Gender.Girls) this.Gender.Boys = true;
			}
		}
	}
};
</script>

<style>
nav > div {
	background-color: #fff;
	margin: 8px 0;
	padding: 8px;
	border-radius: 12px;
}

nav h3 {
	display: inline-block;
	margin: 0;
}

nav label {
	display: block;
	white-space: nowrap;
	overflow: hidden;
}

nav .gender {
	display: flex;
	justify-content: space-around;
}

nav .years {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

nav .years label {
	width: 60px;
}

nav .subjects {
	max-height: calc((100% - 200px) * 0.6);
	overflow: auto;
}

nav .classes {
	max-height: calc((100% - 280px) * 0.4);
	overflow: auto;
}
</style>
